import * as React from 'react';
import { Col, Row } from 'react-grid-system';
import { IHeroBanner } from '../atoms/interfaces';
import theme from '../../styles/theme';
import Container from '../atoms/container';
import styled, { css } from 'styled-components';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';

interface IHeroWrapper {
  backgroundImage?: string;
  isMobile: boolean;
}

const HeroWrapper = styled.div<IHeroWrapper>`
  ${(props) =>
    props.backgroundImage &&
    css`
      background: ${(props: IHeroWrapper) =>
        `url(${props.backgroundImage}) center/cover no-repeat`};
    `};
`;

const HeroBanner: React.FC<IHeroBanner> = ({
  leftPane,
  rightPane,
  backgroundColor = 'primary',
  backgroundImage = undefined
}) => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <HeroWrapper
      backgroundImage={backgroundImage}
      isMobile={IS_MOBILE}
      style={
        backgroundColor
          ? {
              backgroundColor: theme.colors[backgroundColor ?? 'primary']
            }
          : undefined
      }
    >
      <Container>
        <Row align="center">
          <Col md={6} xs={12}>
            {leftPane}
          </Col>
          <Col md={6} xs={12}>
            {rightPane}
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

export default HeroBanner;
