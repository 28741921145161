import React from 'react';
import { Container } from 'react-grid-system';

import HeroBanner from '../organisms/heroBanner';
import Typography from '../atoms/typography';
import Spacer from '../atoms/spacer';
import theme from '../../styles/theme';
import styled from 'styled-components';
import Card from '../atoms/card';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.pageBackground};
`;

const CardWrapper = styled(Card)`
  padding: 2rem;
`;

interface IDocument {
  title: string;
  children: React.ReactNode;
}

const DocumentTemplate: React.FC<IDocument> = ({ children, title }) => {
  return (
    <>
      <HeroBanner
        backgroundColor="secondary"
        leftPane={
          <>
            <Spacer spacing={6} />
            <Typography variant="header" color="textSecondary">
              {title}
            </Typography>
            <Spacer />
          </>
        }
      />
      <Wrapper theme={theme}>
        <Spacer spacing={2} />
        <Container>
          <CardWrapper>{children}</CardWrapper>
        </Container>
        <Spacer spacing={2} />
      </Wrapper>
    </>
  );
};

export default DocumentTemplate;
