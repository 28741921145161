import React, { ReactElement } from 'react';
import { Col, Row } from 'react-grid-system';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import styled from 'styled-components';
import DocumentTemplate from '../components/templates/document';
import SEO from '../components/seo';

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SectionNumber = styled(Typography)`
  width: 40px;
`;

const SectionTitle = styled(Typography)`
  flex: 1;
`;

type Type = 'title' | 'content';

interface Iterms {
  type: Type;
  title: string | ReactElement;
  section: string;
}

const allmanna_villkor_first_section: Iterms[] = [
  {
    type: 'title',
    title: 'TILLÄMPLIGHET OCH DEFINITIONER',
    section: '1'
  },
  {
    type: 'content',
    title:
      'Dessa allmänna villkor tillämpas på alla avtal mellan PB Services AB (559271-7408) (‘Bolaget”) å ena sidan och juridisk eller fysisk person (‘Kunden”) å den andra vid köp av Panbox-konceptet via www.panbox.se (“Webbplatsen”) eller därtill relaterade webbsidor.',
    section: '1.1'
  },
  {
    type: 'content',
    title:
      'Köpeavtalet för Panbox-konceptet inklusive dessa allmänna tjänstevillkor kallas nedan ”Avtalet”.',
    section: '1.2'
  },
  {
    type: 'content',
    title:
      'Avtalet anses ha ingåtts när Bolaget har bekräftat Kundens beställning genom att skicka en orderbekräftelse till Kunden via e-post.',
    section: '1.3'
  },
  {
    type: 'content',
    title:
      'Genom att ingå Avtalet försäkrar Kunden att han/hon förstår och accepterar dessa allmänna tjänstevillkor. Genom att acceptera förbinder sig Kunden att följa de allmänna tjänstevillkoren i sin helhet och försäkrar att han/hon har läst informationen om personuppgifter och samtycke till användningen därav enligt Bolagets dataskyddspolicy.',
    section: '1.4'
  },
  {
    type: 'title',
    title: 'PRODUKT- OCH TJÄNSTEINFORMATION',
    section: '2'
  },
  {
    type: 'content',
    title:
      'Panbox-konceptet inkluderar köp av en produkt (”Panbox”) och en lagringstjänst (”Tjänsten”). Bolaget tillhandahåller Tjänsten genom tredje parts lagringsanläggning. Panboxen kommer att förvaras vid lagringsanläggningen tills Kunden begär att Panboxen levereras genom avrop. Vid avrop levereras Panbox till Kundens registrerade adress. Efter avropet erbjuds i regel möjligheten att köpa en ny Panbox, vilken isåfall kommer beställas och lagras, så att Kunden alltid har en Panbox tillgänglig i lager.',
    section: '2.1'
  },
  { type: 'title', title: '3. PRIS OCH BETALNING', section: '3' },
  {
    type: 'content',
    title:
      'Panbox levereras till lagringsanläggningen till en engångskostnad. Utöver detta debiteras Kunden för Tjänsten löpande, inkluderande alla lagringsavgifter, serviceavgifter och övriga kostnader hänförliga till lagringen. Alla priser och avgifter anges i den aktuella prislistan som finns tillgänglig på Webbplatsen.',
    section: '3.1'
  },
  {
    type: 'content',
    title:
      'Kunden förbinder sig att betala kostnaden för lagringen månadsvis och i förskott. Vid undertecknande av Avtalet ska Kunden betala en engångskostnad för Panbox och den första betalningen för första månadens lagringsavgift i förskott.',
    section: '3.2'
  },
  {
    type: 'content',
    title:
      'När Avtalet ingås väljer Kunden mellan de valfria betalningsmetoder som anges på Webbplatsen. Vid fakturering eller delbetalning kan kreditupplysning komma att inhämtas, vilket Kunden härmed upplyses om. Bolaget förbehåller sig rätten att inte erbjuda alla betalningsmetoder, alternativt ensidigt ändra betalningsmetod om den betalningsmetod som Kunden har valt inte fungerar, av vilka skäl som helst. I det fall företaget inte kan ta emot betalning enligt valt betalningssätt, har Bolaget rätt att skicka en faktura till Kunden, inklusive en administrativ avgift för fakturahanteringen.',
    section: '3.3'
  },
  {
    type: 'content',
    title:
      'Kunden ansvarar för att registrera korrekta fungerande betalningsuppgifter hos Bolaget och hålla dessa uppdaterade så att avtalade avgifter alltid kan betalas.',
    section: '3.4'
  },
  {
    type: 'content',
    title:
      'Kundens dröjsmål med betalning av avgift eller kostnad hänförlig till Avtalet utgör ett avtalsbrott med rätt för Bolaget att säga upp Avtalet enligt avsnitt 15 samt fakturera Kunden dröjsmålsränta.',
    section: '3.5'
  },
  {
    type: 'content',
    title:
      'Även om Kunden begär att Panbox lämnas till en annan mottagare än Kunden, t.ex. om Panbox är en gåva, är Kunden som avtalspart ansvarig för betalningen av alla avgifter som är relaterade till Avtalet och även ansvarig för att fullgöra övriga skyldigheter enligt Avtalet gentemot Bolaget.',
    section: '3.6'
  },
  {
    type: 'title',
    title: 'AVTALSTID OCH UPPSÄGNING',
    section: '4'
  },
  {
    type: 'content',
    title: 'Avtalet är ett löpande avtal med en månads uppsägningstid.',
    section: '4.1'
  },
  {
    type: 'title',
    title: 'ÅNGERRÄTT',
    section: '5'
  },
  {
    type: 'content',
    title:
      'Om Kunden är konsument har Kunden en 14-dagars ångerfrist i enlighet med gällande konsumentskyddslagstiftning. Detta innebär att Kunden har rätt att häva köpet genom att meddela Bolaget om detta inom 14 dagar, räknat från den dag då Avtalet ingicks (ångerfrist).',
    section: '5.1'
  },
  {
    type: 'content',
    title:
      'Om Kunden vill utnyttja sin ångerrätt skall Kunden ta kontakt med Bolaget för erhållande av ångerblankett.',
    section: '5.2'
  },
  {
    type: 'title',
    title: '6. LEVERANS',
    section: '6'
  },
  {
    type: 'content',
    title:
      'Kunden ska förse Bolaget med en svensk adress till vilken leveransen ska ske. Leverans kan inte ske till en adress utanför Sverige. Kunden bär ansvaret för att hålla adressen uppdaterad.',
    section: '6.1'
  },
  {
    type: 'content',
    title:
      'Efter en initial period om 30 dagar efter att Avtal slutits mellan Kunden och Bolaget kan Kunden närsomhelst begära att Panbox levereras. När Kunden avropar leverans levereras Panbox på det sätt som Bolaget finner lämpligt för att hämtas ut vid en specifik leveranspunkt enligt Kundens val. Kunden debiteras en fraktavgift för leveransen som beräknas i samband med att avropet ges.',
    section: '6.2'
  },
  {
    type: 'content',
    title:
      'Paket ska hämtas personligen med giltig legitimation och ordernummer eller genom bud med fullmakt, giltig legitimation för både fullmaktsgivaren och fullmaktshavaren samt ordernummer, om inte annat anges av ansvarig befraktare. Kunden får ett meddelande om leverans som anger när och var paketet ska hämtas eller tas emot. Meddelandet kan lämnas via e-post, post, SMS eller per telefon.',
    section: '6.3'
  },
  {
    type: 'content',
    title:
      'Kunden ansvarar för att hämta paketet vid leveransstället inom den tid som anges i meddelandet. Om paketet inte hämtas i rätt tid har Bolaget rätt att debitera Kunden en tilläggsavgift om 350 SEK.',
    section: '6.4'
  },
  {
    type: 'content',
    title: 'Om Avtalet hävs enligt avsnitt 13 kommer Panbox tillfalla Bolaget.',
    section: '6.5'
  },
  {
    type: 'content',
    title:
      'Om Kunden inte underrättar Bolaget om en leveransadress efter 30 dagar från det att Avtalet avslutats, har Bolaget rätt att efter eget gottfinnande skicka Panbox till den adress som Kunden har angett vid köpet och debitera Kunden för fraktavgiften, eller att skicka Panbox till välgörenhet.',
    section: '6.6'
  }
];
const allmanna_villkor_second_section: Iterms[] = [
  {
    type: 'title',
    title: 'AUTOMATISK BESTÄLLNING VID AVROP',
    section: '7'
  },
  {
    type: 'content',
    title:
      'I samband med avrop enligt punkten 6.2 kommer Bolaget erbjuda Kunden att beställa en ny Panbox på Kundens bekostnad. Den nya Panbox kommer att ersätta den som har skickats till Kunden, för att Kunden alltid ska ha en Panbox tillgänglig i lager inom rimlig tid.',
    section: '7.1'
  },
  {
    type: 'content',
    title:
      'Om Kunden är konsument och vid avropet inte längre önskar en ny Panbox har Kunden enligt svensk lagstiftning rätt att avböja den förfrågan som Bolaget erbjuder. ',
    section: '7.2'
  },
  {
    type: 'title',
    title: 'ÖVERLÅTELSE AV AVTALET',
    section: '8'
  },
  {
    type: 'content',
    title:
      'Kunden har rätt att överföra Avtalet, förutsatt att Bolaget ger sitt tillstånd. Bolaget har rätt att överföra Avtalet till ett annat företag inom samma koncern eller till tredje part som har förvärvat den verksamhet som tillhandahåller Tjänsten, utan att dessförinnan inhämta Kundens godkännande.',
    section: '8.1'
  },
  {
    type: 'content',
    title:
      'Den utträdande Kunden har inget betalningsansvar för förpliktelser som uppstår efter datumet för överlåtelse. Den övertagande Kunden är solidariskt ansvarig tillsammans med den överlåtande Kunden för alla skyldigheter som uppstod före överföringsdatumet och som var kända eller borde ha varit kända för den övertagande Kunden vid datumet för överlåtelse.',
    section: '8.2'
  },
  {
    type: 'title',
    title: 'FÖRVARING AV PANBOX',
    section: '9'
  },
  {
    type: 'content',
    title:
      'Kunden kommer inte att ha åtkomst till Panbox under lagringsperioden och kommer inte heller kunna hämta sin Panbox direkt från tredjepartsleverantören. För tillgång till Panbox krävs avrop enligt punkten 6.2 ovan.',
    section: '9.1'
  },
  {
    type: 'content',
    title:
      'Bolaget ska inte betraktas som ansvarig för lagerhållning eller på annat sätt anses företräda tredje part som svarar för sådan lagerhållning, och inte heller hållas ansvarig för förvaring av Panbox under avtalstiden.',
    section: '9.2'
  },
  {
    type: 'title',
    title: 'MEDDELANDEN OCH ADDRESSÄNDRING',
    section: '10'
  },
  {
    type: 'content',
    title:
      'Efter Avtalets ingående kan Bolaget komma att lämna Kunden meddelanden via post, e-post, SMS eller annan elektronisk kommunikation.',
    section: '10.1'
  },
  {
    type: 'content',
    title:
      'Kunden ansvarar för att den postadress eller e-postadress som Kunden har angivit till Bolaget är aktuell och giltig.',
    section: '10.2'
  },
  {
    type: 'title',
    title: '11. PERSONUPPGIFTER',
    section: '11'
  },
  {
    type: 'content',
    title:
      'Bolagets hantering av personuppgifter omfattas av Allmänna Dataskyddsförordningen (GDPR). All information som behandlas i enlighet med Avtalet omfattas av Bolagets Dataskyddspolicy.',
    section: '11.1'
  },
  {
    type: 'title',
    title: 'TILLÄGG ELLER ÄNDRINGAR',
    section: '12'
  },
  {
    type: 'content',
    title:
      'Bolaget har rätt att göra ändringar i dessa allmänna tjänstevillkor. Innan en ändring genomförs kommer Kunden att informeras om ändringen via post, e-post eller via Webbplatsen. De uppdaterade allmänna tjänstevillkoren träder i kraft 30 dagar efter att informationen gavs till Kunden eller gjordes tillgänglig på Webbplatsen.',
    section: '12.1'
  },
  {
    type: 'content',
    title:
      'Eventuella ändringar av Avtalet till nackdel för Kunden får inte träda i kraft före utgången av den första 12-månadersperioden av Avtalet. Bolaget kan dock närsomhelst ändra villkoren om ändringen beror på en förändring i den allmänna prisnivån, en höjning av skatter eller avgifter, ökade priser hos externa underleverantörer, beslut från myndighet eller ändring i lagstiftning. Kundens rätt att säga upp Avtalet i sådana fall framgår av punkten 12.3 nedan.',
    section: '12.2'
  },
  {
    type: 'content',
    title:
      'Om Bolaget har ändrat villkoren i Avtalet till nackdel för Kunden, kan Kunden säga upp Avtalet från och med ikraftträdandedagen för de uppdaterade allmänna tjänstevillkoren, om uppsägningen meddelas inom 30 dagar efter informationen gavs till Kunden. Kunden anses ha accepterat ändringarna om Kunden inte säger upp Avtalet innan ändringarna träder i kraft.',
    section: '12.3'
  },
  {
    type: 'title',
    title: 'AVTALSBROTT OCH FÖRTIDA UPPSÄGNING',
    section: '13'
  },
  {
    type: 'content',
    title: (
      <>
        Bolaget har rätt att när som helst säga upp Avtalet till omedelbart
        upphörande, utan föregående avisering eller påverkan på Bolagets
        rättigheter enligt Avtalet, om Kunden:
        <br />
        {`a) bryter mot någon del av Avtalet,`}
        <br />
        {`b) inte betalar avgifter som beror på Avtalet i sin helhet på`}
        förfallodagen; eller
        <br />
        {`c) är föremål för konkurs, likvidation eller ställer in sina
        betalningar.`}
      </>
    ),
    section: '13.1'
  },
  {
    type: 'content',
    title:
      'Bolaget har, vid uppsägning av Avtalet enligt punkten 13.1, rätt till betalning för alla förluster, kostnader, avgifter och andra belopp som ska betalas på grund av uppsägningen.',
    section: '13.2'
  },
  {
    type: 'content',
    title:
      'Bolaget har rätt att säga upp Avtalet med omedelbar verkan på grund av en oförutsedd förändring av den allmänna prisnivån, högre skatter och avgifter, ökade priser från externa underleverantörer, beslut från myndighet eller ändring i lagstiftning.',
    section: '13.3'
  },
  {
    type: 'title',
    title: 'FORCE MAJEURE',
    section: '14'
  },
  {
    type: 'content',
    title:
      'Ingen av Parterna ska gentemot den andra Parten ansvara för underlåtenhet till eller försening av fullgörelse av dennes skyldigheter enligt Avtalet eller för annan underlåtenhet, om sådan försening eller underlåtenhet är utanför Parts kontroll (”Force Majeure Händelse”), om sådan Force Majeure Händelse förhindrar, väsentligen försvårar eller annars försenar Parts fullgörelse. Samma ansvarsbefriande verkan ska gälla om händelsen påverkar Bolagets leverantörer.',
    section: '14.1'
  },
  {
    type: 'content',
    title:
      'Med ”Force Majeure Händelse” avses exempelvis men inte uteslutande åtgärd eller underlåtenhet från myndighet, myndighetsbeslut eller föreskrift, ny eller ändrad lagstiftning, strejk eller annan arbetskonflikt, blockad, krig, upplopp, sabotage, extrema väderförhållanden, blixtnedslag, brand, explosion, översvämning, naturkatastrof, terrorism, olycksfall, eller av någon annan omständighet utanför Parts skäliga kontroll (”Force Majeure-händelse”).',
    section: '14.2'
  },
  {
    type: 'title',
    title: 'TVISTELÖSNING',
    section: '15'
  },
  {
    type: 'content',
    title: 'Avtalet ska tolkas enligt svensk rätt.',
    section: '15.1'
  },
  {
    type: 'content',
    title:
      'Tvist i anledning av Avtalet ska avgöras av domstol i den domsaga där Kunden har sin hemvist eller säte.',
    section: '15.2'
  }
];

const AllmannaVillkor = () => {
  return (
    <>
      <SEO title="Almänna tjänstevillkor" />
      <DocumentTemplate title="Almänna tjänstevillkor">
        <Row>
          <Col md={6}>
            {allmanna_villkor_first_section.map((item) => {
              return (
                <React.Fragment key={item.section}>
                  <ListWrapper>
                    <SectionNumber
                      variant={item.type === 'title' ? 'h4' : 'body'}
                    >
                      {item.section}
                    </SectionNumber>
                    <SectionTitle
                      variant={item.type === 'title' ? 'h4' : 'body'}
                    >
                      {item.title}
                    </SectionTitle>
                  </ListWrapper>
                  <Spacer />
                </React.Fragment>
              );
            })}
          </Col>

          <Col md={6}>
            {allmanna_villkor_second_section.map((item) => {
              return (
                <React.Fragment key={item.section}>
                  <ListWrapper>
                    <SectionNumber
                      variant={item.type === 'title' ? 'h4' : 'body'}
                    >
                      {item.section}
                    </SectionNumber>
                    <SectionTitle
                      variant={item.type === 'title' ? 'h4' : 'body'}
                    >
                      {item.title}
                    </SectionTitle>
                    <Spacer />
                  </ListWrapper>
                  <Spacer />
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </DocumentTemplate>
    </>
  );
};

export default AllmannaVillkor;
