import React from 'react';
import styled from 'styled-components';

const StyledCard = styled.div`
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.cardBackground};
  border: none;
  margin-top: 1rem;
`;

const Card: React.FC = (props) => <StyledCard {...props} />;

export default Card;
